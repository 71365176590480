import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const ListLink = props => (
  <li style={{ listStyle: `none`, display: `inline-block`, margin: `0 2em 1em .9em` }}>
    <Link
      to={props.to}
      style={{ color: `black`, boxShadow: `none`, borderBottom: `none` }}
    >
      {props.children}
    </Link>
  </li>
)

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      position: `relative`,
    }}
  >
    <Link to="/" style={{ boxShadow: `none` }}>
      <h1
        style={{
          color: `black`,
          fontSize: `2rem`,
          fontFamily: "Georgia, serif",
          textAlign: `center`,
          padding: `.5em 0 0 0`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}{" "}
      </h1>
    </Link>
    <nav style={{ textAlign: `center` }}>
      <ul>
        <ListLink to="/">Home</ListLink>
        <ListLink to="/books">Books</ListLink>
        <ListLink to="/about">About</ListLink>
        <ListLink to="/events">Events</ListLink>
        <ListLink to="/essays">Essays</ListLink>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
